.formMedico {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    width: 100%;
}

.input {
    width: calc(100% - 40px);
    margin: 0px 20px 3px;
}

.input-col:first-child {
    width: 100%;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 5px;
    margin-bottom: 3px;
}

.input-col:last-child {
    width: 100%;
    margin-top: 0px;
    margin-right: 20px;
    margin-left: 5px;
    margin-bottom: 3px;
}

.button {
    margin: 5px 27px 3px;
}

.button:hover,
.center button:hover {
    cursor: pointer;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center button {
    width: 50px;
    height: 25px;
    margin-right: 20px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary);
    color: #fff;
    text-align: center;
}

.col-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.containerSelectEspecialidade {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 15px;
    margin-bottom: 5px;
}

.footer:first-child  {
    margin-left: 20px;
}

